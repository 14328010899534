import React, { createContext, useContext, useState } from 'react';

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({
        searchSettings: {
            minRefreshTime: 5,
            maxRefreshTime: 9,
            stopAfterSearches: 20,
            noOfPlayersPerPage: 50,
            pageMode: 1,
            // pageModeAllowance: 10,
            priceMode: 'futbin',
            // autoSearchEnabled: false,
            pauseAfter:100,
            pauseTime: 20,
        },
        autoBuySettings: {
            profitType: 'fixed value',
            minProfitPercentage: 0,
            minGuaranteedProfit: 5000,
            autoListEnabled:false,
            autoList: 'current price',
            noOfBuys: 5,
        },
        notificationSettings: {
            discordWebhookUrl: '',
        },
    });

    const updateSetting = (category, key, value) => {
        setSettings((prev) => ({
            ...prev,
            [category]: {
                ...prev[category],
                [key]: value,
            },
        }));
    };

    return (
        <SettingsContext.Provider value={{ settings, updateSetting }}>
            {children}
        </SettingsContext.Provider>
    );
};
