import { Box, Typography, Grid, Paper } from '@mui/material';
import LogTerminal from './LogTerminal';
// import SearchLog from './SearchLog';
import { useAutobuyerData } from '../contexts/autobuyerContext';

const Autobuyer = ({ searchResult }) => {
    const { analyticsData, elapsedTime, sleepTimer, logData } = useAutobuyerData();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '95vh', p: 1 }}>
            <Grid container spacing={1} sx={{ flex: '1 1 30%' }}>
                <Grid item xs={4}>
                    <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Profit</Typography>
                        <Typography variant="h5" color="orange">{analyticsData.profit}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Searches</Typography>
                        <Typography variant="h5" color="red">{analyticsData.searches}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Time Elapsed</Typography>
                        <Typography variant="h5">{elapsedTime}</Typography>
                    </Paper>
                </Grid>


                <Grid item xs={4}>
                    <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Purchases</Typography>
                        <Typography variant="h5" color="green">{analyticsData.successfulPurchases}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Failed Purchases</Typography>
                        <Typography variant="h5" color="red">{analyticsData.failedPurchases}</Typography>
                    </Paper>
                </Grid>
                {/* <Grid item xs={4}>
                    <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Active Transfers</Typography>
                        <Typography variant="h5">{analyticsData.activeTransfers}</Typography>
                    </Paper>
                </Grid> */}
                {/* <Grid item xs={6}>
                    <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Sold Items</Typography>
                        <Typography variant="h5">{analyticsData.soldItems}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Unsold Items</Typography>
                        <Typography variant="h5" color="red">{analyticsData.unsoldItems}</Typography>
                    </Paper>
                </Grid> */}
                <Grid item xs={4}>
                    <Paper elevation={3} sx={{ p: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle2">Sleep</Typography>
                        <Typography variant="h5">{sleepTimer}</Typography>
                    </Paper>
                </Grid>
            </Grid>


            <Box sx={{ flex: '1 1 70%', mt: 1, display: 'flex', gap: 1 }}>
                <Box sx={{ flex: 1 }}>
                    <LogTerminal logs={logData} />
                </Box>
                {/* <Box sx={{ flex: 1 }}>
                    <SearchLog searchLogs={searchResult} />
                </Box> */}
            </Box>
        </Box>
    );
};

export default Autobuyer;