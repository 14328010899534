import React from 'react';
import { List, ListItemButton, ListItemIcon, Drawer, Box, Toolbar, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { navLabel, navRoutes, navIcons } from '../const';
import { useAuth } from '../contexts/loginAuthContext';
import LogoutIcon from '@mui/icons-material/Logout';

const Sidebar = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: 60,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: 60, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '50%' }}>
                <List sx={{ padding: 0 }}>
                    {navLabel.map((text, index) => (
                        <Tooltip title={text} placement="right" key={text}>
                            <ListItemButton 
                                onClick={() => { navigate(`/${navRoutes[index]}`) }} 
                                sx={{ 
                                    justifyContent: 'center', 
                                    minHeight: 60, // Adjust this value to control vertical spacing 
                                    padding: 0 
                                }}
                            >
                                <ListItemIcon sx={{ minWidth: 0 }}>
                                    {navIcons[index]}
                                </ListItemIcon>
                            </ListItemButton>
                        </Tooltip>
                    ))}
                    <Tooltip title="Logout" placement="right" key="logout">
                        <ListItemButton 
                            onClick={logout} 
                            sx={{ 
                                justifyContent: 'center', 
                                minHeight: 60, // Adjust this value to control vertical spacing 
                                padding: 0 
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 0 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </List>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
