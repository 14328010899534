import { useState, useEffect, createContext, useContext } from "react";

const autobuyerContext = createContext();

export const useAutobuyerData = () => {
    return useContext(autobuyerContext);
}

export const AutobuyerProvider = ({ children }) => {
    // const [autobuyerData, setAutobuyerData] = useState({
    //     autobuyer: false,
    //     autobuyerStatus: 'Stopped',
    //     autobuyerLogs: [],
    //     autobuyerSettings: {
    //         maxBids: 5,
    //         maxPrice: 10000,
    //         minProfit: 200,
    //         maxProfit: 1000,
    //         searchInterval: 500,
    //         searchPrice: 1000,
    //         searchPriceEnabled: false,
    //         searchPlayer: false,
    //         searchStaff: false,
    //         searchClub: false,
    //         searchLeague: false,
    //         searchNation: false,
    //         searchPosition: false,
    //         searchChemistryStyle: false,
    //         searchRare: false,
    //         searchQuality: false,
    //         searchRarity: false,
    //         searchZone: false,
    //         searchPlayStyle: false,
    //         searchIconTraits: false,
    //         searchMinBid: false,
    //         searchMaxBid: false,
    //         searchMinBuy: false,
    //         searchMaxBuy: false,
    //     },
    // });
    const [isBotActive, setIsBotActive] = useState(false);
    const [elapsedTime, setElapsedTime] = useState('0:00');
    const [isPaused, setIsPaused] = useState(false);
    const [sleepTimer, setSleepTimer] = useState(null);
    const [logData, setLogData] = useState([]);
    const [analyticsData, setAnalyticsData] = useState({
        profit: 0,
        searches: 0,
        successfulPurchases: 0,
        failedPurchases: 0,
        activeTransfers: 0,
        soldItems: 0,
        unsoldItems: 0
    });

    const startElapsedTime = () => {
        setIsPaused(false);
    };

    // Function to pause the elapsed time
    const pauseElapsedTime = () => {
        setIsPaused(true);
    };

    // Function to start the sleep timer
    const startSleepTimer = (duration) => {
        setSleepTimer(duration);
    };

    // update analyticsData handler
    const updateAnalyticsData = (key,value) => {
        setAnalyticsData((prev) => ({
            ...prev,
            [key]: value
        }));
    };

    // set log data handler - each log is an object with a message and a type {type,message,time: getTime()}
    const updateLog = (log) => {
        setLogData((prev) => [...prev, log]);
    };


    useEffect(() => {
        if (!isPaused && isBotActive) {
            const timer = setInterval(() => {
                const time = elapsedTime.split(':');
                let minutes = parseInt(time[0]);
                let seconds = parseInt(time[1]);

                if (seconds < 59) {
                    seconds++;
                } else {
                    seconds = 0;
                    minutes++;
                }

                setElapsedTime(`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [isPaused, isBotActive, elapsedTime]);

    // Sleep timer effect
    useEffect(() => {
        if (sleepTimer !== null && sleepTimer > 0) {
            const sleepTimeout = setTimeout(() => {
                setSleepTimer(sleepTimer - 1);
            }, 1000);

            return () => {
                clearTimeout(sleepTimeout);
            };
        }
    }, [sleepTimer]);

    return (
        <autobuyerContext.Provider value={{ elapsedTime, sleepTimer, isPaused, analyticsData, logData, isBotActive, startElapsedTime, pauseElapsedTime, startSleepTimer, updateAnalyticsData, updateLog, setIsBotActive }}>
            {children}
        </autobuyerContext.Provider>
    )
}