import { getFilterData, getClubFilters, getLeagueFilters, getRarityFilters } from './getFilterData';
import {fetchPlayerDetails} from './getPlayers';

const getNextUpdateTimestamp = (targetDay = 5, targetHour = 17, targetMinute = 0) => {
    const now = new Date();
    const currentDay = now.getDay();

    let daysUntilTarget = targetDay - currentDay;
    if (daysUntilTarget < 0 || (daysUntilTarget === 0 && now.getHours() >= targetHour)) {
        daysUntilTarget += 7;
    }

    const nextUpdateDate = new Date(now);
    nextUpdateDate.setDate(now.getDate() + daysUntilTarget);
    nextUpdateDate.setHours(targetHour, targetMinute, 0, 0);

    return nextUpdateDate.getTime();
};

const isTimeForWeeklyUpdate = (nextUpdateTimestamp) => {
    return Date.now() >= nextUpdateTimestamp;
};

async function fetchOrUpdateFromStorage(key, fetchFunction, scheduleUpdate = false) {
    try {
        const storedData = localStorage.getItem(key);
        let nextUpdateTimestamp = getNextUpdateTimestamp();

        if (storedData) {
            const parsedData = JSON.parse(storedData);
            nextUpdateTimestamp = parsedData.nextUpdateTimestamp || nextUpdateTimestamp;

            if (scheduleUpdate && isTimeForWeeklyUpdate(nextUpdateTimestamp)) {
                const data = await fetchFunction();
                if (!isValidData(data)) {
                    console.error(`Failed to fetch valid data for ${key}. Falling back to stored data.`);
                    return parsedData.data;
                }
                nextUpdateTimestamp = getNextUpdateTimestamp();
                localStorage.setItem(key, JSON.stringify({ data, nextUpdateTimestamp }));
                return data;
            }

            if (!isValidData(parsedData.data)) {
                console.error(`Stored data for ${key} is invalid. Fetching fresh data.`);
                const data = await fetchFunction();
                nextUpdateTimestamp = getNextUpdateTimestamp();
                localStorage.setItem(key, JSON.stringify({ data, nextUpdateTimestamp }));
                return data;
            }

            return parsedData.data;
        }

        const data = await fetchFunction();
        if (!isValidData(data)) {
            console.error(`Failed to fetch data for ${key}. Defaulting to an empty object.`);
            return {};
        }
        nextUpdateTimestamp = getNextUpdateTimestamp();
        localStorage.setItem(key, JSON.stringify({ data, nextUpdateTimestamp }));
        return data;
    } catch (error) {
        console.error(`Error fetching or storing ${key}:`, error);
        return {};
    }
}

function isValidData(data) {
    if (Array.isArray(data)) {
        return data.length > 0;
    } else if (data && typeof data === 'object') {
        return Object.keys(data).length > 0;
    }
    return false;
}

async function fetchOrSetBasicOptions(key, defaultOptions) {
    const storedData = localStorage.getItem(key);

    if (storedData) {
        return JSON.parse(storedData).data;
    }

    const data = defaultOptions;
    localStorage.setItem(key, JSON.stringify({ data, nextUpdateTimestamp: null }));
    return data;
}

export let qualityOptions = [];
export let positionsOptions =[];
export let zoneOptions =[];
export let clubOptions=[];
export let filterOptionsData=[];
export let rarityOptions=[];
export let leagueOptions=[];
export let playerOptions=[];

export async function initializeOptions() {
    qualityOptions = await fetchOrSetBasicOptions('qualityOptions', {
        Bronze: 'bronze',
        Silver: 'silver',
        Gold: 'gold',
        Special: 'SP',
    });

    positionsOptions = await fetchOrSetBasicOptions('positionsOptions', [
        'GK',
        'RWB',
        'RB',
        'CB',
        'LB',
        'LWB',
        'CDM',
        'RM',
        'CM',
        'LM',
        'CAM',
        'CF',
        'RW',
        'ST',
        'LW',
    ]);

    zoneOptions = await fetchOrSetBasicOptions('zoneOptions', {
        Defenders: 'defence',
        Midfielders: 'midfield',
        Attackers: 'attacker',
    });

    clubOptions = await fetchOrUpdateFromStorage('clubOptions', getClubFilters);
    filterOptionsData = await fetchOrUpdateFromStorage('filterOptionsData', getFilterData);
    rarityOptions = await fetchOrUpdateFromStorage('rarityOptions', getRarityFilters, true);
    leagueOptions = await fetchOrUpdateFromStorage('leagueOptions', getLeagueFilters, true);
    playerOptions = await fetchOrUpdateFromStorage('playerOptions', fetchPlayerDetails, true);

    return {
        qualityOptions,
        positionsOptions,
        zoneOptions,
        clubOptions,
        filterOptionsData,
        rarityOptions,
        leagueOptions,
        playerOptions,
    };
}