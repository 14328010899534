import axios from "axios";
import { sleep, baseUrl } from '../const';

export const getTime = () => {
    return new Date().toLocaleTimeString()
}

export const getRandomRefreshTime = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Function to manually build the query string
export const buildManualQueryString = (filters, sid) => {
    let queryString = `num=${filters.num}&start=${filters.start}&type=${filters.type}`;

    // Append filters if they have valid values
    if (filters.maskedDefId) queryString += `&maskedDefId=${filters.maskedDefId}`;
    if (filters.lev && filters.lev !== filters.rare) queryString += `&lev=${filters.lev}`;
    if (filters.rare) queryString += `&rare=${filters.rare}`;
    if (filters.rarityIds) queryString += `&rarityIds=${filters.rarityIds}`;
    if (filters.pos && filters.pos !== filters.zone) queryString += `&pos=${filters.pos}`;
    if (filters.zone) queryString += `&zone=${filters.zone}`;
    if (filters.playStyle) queryString += `&playStyle=${filters.playStyle}`;
    if (filters.nat) queryString += `&nat=${filters.nat}`;
    if (filters.leag) queryString += `&leag=${filters.leag}`;
    if (filters.team) queryString += `&team=${filters.team}`;
    if (filters.icontraits) queryString += `&icontraits=${filters.icontraits}`;
    if (filters.micr) queryString += `&micr=${filters.micr}`;
    if (filters.macr) queryString += `&macr=${filters.macr}`;
    if (filters.minb) queryString += `&minb=${filters.minb}`;
    if (filters.maxb) queryString += `&maxb=${filters.maxb}`;

    // Append session ID
    queryString += `&sid=${sid}`;
    return queryString;
};

export const formatExpiryTime = (remainingTime) => {
    if (remainingTime <= 0) {
        return 'Expired';
    }

    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    if (hours > 0) {
        return `${hours}h`;
    } else if (minutes > 0) {
        return `${minutes}m`;
    } else {
        return `${seconds}s`;
    }
};

export const getRarityName = (rarityId) => {
    const rarityOptions = JSON.parse(localStorage.getItem('rarityOptions')).data;
    const rarity = rarityOptions.find((rarity) => parseInt(rarity.id) === rarityId);
    return rarity.name;
}

export const getFilterRange = async (pageMode, pageModeAllowance) => {
    if (pageMode === 1) {
        // if (pageModeAllowance <= 0) {
        //     return { min: 3598, max: 3600 };
        // }
        const min = 3540
        const max = 7199

        return { min, max };
    }
}
export const getPriceFromDB = async (priceMode, playerId, rating, rarity, nation, league, club) => {
    const payload = {
        playerId: playerId,
        rating: rating,
        rarity: rarity,
        nation: nation,
        league: league,
        club: club
    }

    if (priceMode === 'futbin') {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}/player/getprices1`,
            headers: {
                'accept': '*/*',
                'content-type': 'application/json'
            },
            data: payload
        };

        try {
            const response = await axios.request(config);
            const data = JSON.parse(response.data);
            // console.log(data)
            return data;
        } catch (error) {
            console.log(`Error fetching price for player Id ${playerId} -`, error);
            console.log(playerId, rating, rarity, nation, league, club)
            return null;
        }
    } else {
        return null;
    }
}

export const getPlayername = async (playerId) => {
    const playerData = JSON.parse(localStorage.getItem('playerOptions')).data;
    const player = playerData.find((player) => parseInt(player.id) === parseInt(playerId));
    const playerName = player?.f?.concat(' ', player?.l);

    if (playerName) {
        return playerName;
    } else {
        try {
            const resp = await axios.get(`${baseUrl}/player/playername?assetId=${playerId}`);
            return resp.data.name;
        } catch (e) {
            return null;
        }
    }
}

export const findTargetPage = async (updatedFilters, sessionId, isBotActiveRef, abortControllerRef, setCurrentPage) => {
    let page = 0;
    let closestPage = 0;
    let closestExpiryDifference = Number.MAX_VALUE;

    while (page < 50 && isBotActiveRef.current) {
        updatedFilters.start = page * updatedFilters.num;
        const queryString = buildManualQueryString(updatedFilters, sessionId);
        setCurrentPage(page)
        console.log('Checking page:', page, 'Query string:', queryString);

        try {
            const response = await axios.get(`${baseUrl}/transfermarket/search?${queryString}`, {
                signal: abortControllerRef.current.signal,
            });
            const auctionInfo = response.data.auctionInfo;
            console.log('No of results:', auctionInfo);

            if (!auctionInfo || auctionInfo.length === 0) {
                console.log('No more data on this page. Stopping search.');
                break;
            }

            const firstExpiry = auctionInfo[0].expires;
            const lastExpiry = auctionInfo[auctionInfo.length - 1].expires;
            console.log('Expiries: ', firstExpiry, lastExpiry);

            const expiryDifference = Math.min(
                Math.abs(firstExpiry - 3600),
                Math.abs(lastExpiry - 3590)
            );

            if (expiryDifference < closestExpiryDifference) {
                closestExpiryDifference = expiryDifference;
                closestPage = page;
            }

            if (firstExpiry <= 3600 && lastExpiry >= 3590) {
                console.log('Found page within target range:', page);
                return page;
            }
            console.log('closest page and diff - ', closestPage, closestExpiryDifference)
            const refreshTime = getRandomRefreshTime(3, 7);
            console.log('Sleep for : ', refreshTime);
            await sleep(refreshTime * 1000);
            page++;
        } catch (error) {
            console.error('Error during incremental search:', error);
            console.log('Sleep for : 5s');
            await sleep(5000);
        }
    }
    return closestPage;
};

export const getTradeStatus = async (tradeIds,sid) => {
    let queryString = `tradeIds=${tradeIds}&sid=${sid}`;
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:`${baseUrl}/player/card/tradeStatus?${queryString}`,
        headers:{}
    }
    try {
        const resp = await axios.request(config)
        return resp.data
    } catch (error) {
        console.error('Error fetching trade status:', error);
        return null;
    }
};

export const searchPlayer = async (filters,playerId,sid) => {
    const searchFilter = {
        ...filters,
        maskedDefId: playerId,
    }
    const queryString = buildManualQueryString(searchFilter, sid);
    try {
        const response = await axios.get(`${baseUrl}/transfermarket/search?${queryString}`);
        return response.data.auctionInfo;
    } catch (error) {
        console.error('Error fetching player search result:', error);
        return [];
    }
}