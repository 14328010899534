import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Divider,
    Card,
    Typography
} from '@mui/material';

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);
const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

const EnhancedTransferList = ({ selectedItems, onChange }) => {
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    useEffect(() => {
        const fetchRarityOptions = () => {
            const rarityOptions = JSON.parse(localStorage.getItem('rarityOptions')) || [];
            setLeft(rarityOptions.data || []);
        };

        fetchRarityOptions();
    }, []);

    useEffect(() => {
        if (!selectedItems) return;
        if (left.length === 0) return;

        const selectedIds = selectedItems
            .split(',')
            .map((id) => parseInt(id.trim(), 10))
            .filter((id) => !isNaN(id));

        const selectedItemsToMove = left.filter((item) => selectedIds.includes(parseInt(item.id)));
        if (selectedItemsToMove.length > 0) {
            setRight(() => [...selectedItemsToMove]);
            setLeft((prevLeft) => not(prevLeft, selectedItemsToMove));
        }
        // eslint-disable-next-line
    }, [selectedItems, left.length]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
        handleOnChange(right.concat(left));
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        handleOnChange(right.concat(leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        handleOnChange(not(right, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
        handleOnChange([]);
    };

    const handleOnChange = (updatedRight) => {
        const selectedIds = updatedRight.map((item) => item.id).join(',');
        onChange(`${selectedIds}`);
    };

    const customList = (items, title) => (
        <Card>
            <Typography component="div" variant="button" p={1}>
                {title}
            </Typography>
            {/* <CardHeader
                sx={{ px: 2, py: 1, position: 'sticky' }}
                title={title}
            /> */}
            <Divider />
            <List sx={{
                width: 350,
                height: 230,
                bgcolor: 'background.paper',
                overflow: 'auto',
            }} dense component="div" role="list">
                {items.map((item) => {
                    const labelId = `transfer-list-item-${item.id}-label`;

                    return (
                        <ListItem key={item.id} role="listitem" button onClick={handleToggle(item)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${item.name}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                <Grid item>{customList(left, 'All Available Rarities')}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label="move all right"
                        >
                            ≫
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Divider flexItem />
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            aria-label="move all left"
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList(right, 'Selected Rarities')}</Grid>
            </Grid>
        </Box>
    );
};

export default EnhancedTransferList;
