import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SettingsIcon from '@mui/icons-material/Settings';


const navLabel = ['Transfers', 'Notification', 'Settings']
const navRoutes = ['', 'notifications', 'settings']
const navIcons = [<CompareArrowsIcon />, <NotificationsActiveIcon />, <SettingsIcon />]

// -----------------API Base Url-----------------------------
const baseUrl = 'https://api.fcsnipingbot.hstn.line.pm'
// const baseUrl = 'http://futb.theworkpc.com:5000'

export async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export {
    navLabel,
    navRoutes,
    navIcons,
    baseUrl,
}