import axios from "axios";
import { baseUrl } from "../const";

// ---------------------Get Chemistry style and Nationality Filter data-----------------
export async function getFilterData() {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/filters/gamedata`,
        headers: {}
    };

    try {
        const response = await axios.request(config);
        const data = response.data
        return data;
    } catch (error) {
        console.log('Error fetching nation filters - ',error)
        return []
    }
}

// -----------------------------Club data------------------------------------------
export async function getClubFilters(){
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:`${baseUrl}/filters/clubs`,
        headers:{}
    }
    try {
        const clubs = await axios.request(config)
        return clubs.data
    } catch (error) {
        console.log('Error fetching club filters - ',error)
        return []
    }
}

// -----------------------------League data------------------------------------------
export async function getLeagueFilters(){
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:`${baseUrl}/filters/leagues`,
        headers:{}
    }
    try {
        const resp = await axios.request(config)
        return resp.data
    } catch (error) {
        console.log('Error fetching league filters - ',error)
        return []
    }
}

// -----------------------------Rarity data------------------------------------------
export async function getRarityFilters(){
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:`${baseUrl}/filters/rarities`,
        headers:{}
    }
    try {
        const resp = await axios.request(config)
        return resp.data
    } catch (error) {
        console.log('Error fetching rarity filters - ',error)
        return []
    }
}