import axios from 'axios';
import { baseUrl } from '../const';

export const accountInfo = {
    pid: '',
    personaId: '',
    platform: '',
    skuAccessList:'',
    credits: 0
};

async function getCredits(sid) {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/credits?sid=${sid}`,
        headers: {}
    };

    try {
        const response = await axios.request(config);
        console.log(response.data.credits)
        return response.data.credits;
    } catch (error) {
        console.log('Error fetching credits - ', error)
        return []
    }
}

export async function getAuthCode(access_token) {
    // Get the Auth Code using the access token
    let authCodeConfig = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/auth/authcode?access_token=${access_token}`,
        headers: {}
    };

    try {
        const response = await axios.request(authCodeConfig);
        console.log('Auth code - ', response.data)
        return response.data;
    } catch (error) {
        console.error('Error during fetching Auth code:', error);
        throw error;
    }
}

async function getPid(access_token) {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/auth/pid?access_token=${access_token}`,
        headers: {}
    };
    try {
        const pidResp = await axios.request(config)
        console.log('pid - ', pidResp.data.pid.pidId)
        return pidResp.data.pid.pidId
    } catch (error) {
        console.log(error)
        throw error
    }
}

async function getPersonaId(access_code, pidId) {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/auth/persona?access_code=${access_code}&pidid=${pidId}`,
        headers: {}
    };

    try {
        const personaResp = await axios.request(config);
        
        console.log('Persona Id - ', personaResp.data.personaId)
        console.log('Platform - ', personaResp.data.platform)
        console.log('gameSku - ', personaResp.data.skuAccessList)
        
        if(personaResp.data.platform.includes('PC') || personaResp.data.platform.includes('pc')){
            accountInfo.platform = 'PC'
        }else{
            accountInfo.platform = 'PS'
        }
        accountInfo.personaId = personaResp.data.personaId
        accountInfo.skuAccessList = personaResp.data.skuAccessList

        return personaResp.data.personaId;
    } catch (error) {
        console.log('Error getting persona Id - ', error);
        throw error;
    }
}

async function getSid(access_code, personaId, gameSku) {
    let sidConfig = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/auth/sid?access_code=${access_code}&personaid=${personaId}&gameSku=${gameSku}`,
        headers: {}
    };

    try {
        const finalResponse = await axios.request(sidConfig);
        return finalResponse.data['sid'];
    } catch (error) {
        console.log('Error when fetching SID : ', error);
        throw error;
    }
}

export async function getAuth(access_token) {
    try {
        // If the persona Id and pid is already fetched no need to fetch again so directly getting the new session id
        if (accountInfo.pid && accountInfo.personaId) {
            const accessCodeForSid = await getAuthCode(access_token);
            const sid = await getSid(accessCodeForSid, accountInfo.personaId, Object.keys(accountInfo.skuAccessList)[0]);
            console.log('Not first time authenticating, sid - ', sid)
            return sid
        }

        //Access code is used to get any type of account related requests.
        //This access code expires after 1 use so you need to get another one if needed.
        const access_code = await getAuthCode(access_token);
        const pid = await getPid(access_token)
        accountInfo.pid = pid;

        // Gets persona Id of the user. It varies according to the user.
        const personaId = await getPersonaId(access_code, pid);
        accountInfo.personaId = personaId

        // Again getting Access code to generate the session Id
        const accessCodeForSid = await getAuthCode(access_token);
        const sid = await getSid(accessCodeForSid, personaId, Object.keys(accountInfo.skuAccessList)[0]);
        if(sid){
            accountInfo.credits = await getCredits(sid);
        }
        console.log('first time authenticating, sid - ', sid)
        return sid
    } catch (e) {
        console.log('Error Authenticating the User - ', e);
        return null
    }
}