// src/pages/Login.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box, Paper } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { useAuth } from '../contexts/loginAuthContext';
import { baseUrl } from '../const';
import { useSession } from '../contexts/sessionContext';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { token, updateToken} = useSession();
    const { login } = useAuth();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const newToken = queryParams.get('token');
        
        // Check if a new token was passed and compare it with the current session token
        if (newToken && newToken !== token) {
            updateToken(newToken); // Update session token using session context
            toast.success('Access token acquired and updated successfully');
        }else if (!token) {
            toast.error('Access token not passed. Cannot authenticate. Relaunch the application');
        }
    }, [token, updateToken]);

    const validateForm = () => {
        let isValid = true;
        setError(''); // Clear general error message

        if (!email) {
            setError("Email is required");
            isValid = false;
        }

        if (!password) {
            setError((prevError) => prevError.concat(",Password is required"));
            isValid = false;
        }

        return isValid;
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (!isValid) {
            return;
        }
        const config = {
            method: 'get',
            url:`${baseUrl}/auth/login?email=${email}&password=${password}`,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try{
            const resp = await axios.request(config);
            if(resp.data.success){
                login(resp.data.user);
            }else{
                toast.error(resp.data.message)
            }
        }catch(err){
            toast.error('Error during login');
            console.log(err)
        }
    };

    return (
        <Container maxWidth="xs" sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Paper elevation={6} sx={{ p: 4, borderRadius: 2, width: '100%' }}>
                <Typography variant="h4" gutterBottom align="center">
                    Login
                </Typography>
                {error && (
                    error.split(',').map((err,index) => {
                        return (
                            <Typography key={index} color="error" variant="body2" gutterBottom>
                                {err}
                            </Typography>
                        )
                    })
                )}
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, py: 1.5 }}
                    >
                        Login
                    </Button>
                </form>
                <Box mt={2}>
                    <Typography variant="body2">
                        Don't have an account? <Link to="/">Sign up</Link>
                    </Typography>
                </Box>
            </Paper>
            <Toaster
                position="top-right"
                reverseOrder={true}
                toastOptions={{
                    success: {
                        duration: 3000
                    }
                }}
            />
        </Container>
    );
};

export default Login;
