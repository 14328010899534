import axios from 'axios';
import { baseUrl } from '../const';

export async function buyPlayer(tradeId,price,sid) {
    const payload = {
        tradeId:parseInt(tradeId),
        bidAmount:parseInt(price),
        sid:sid
    }
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}/transfermarket/buy`,
        headers: {
            'accept': '*/*',
            'content-type': 'application/json'
        },
        data:payload
    };
    try {
        const buyResp = await axios.request(config);
        return buyResp.data
    } catch (e) {
        console.log('Error while buying the player - ', e)
        return null
    }
}