import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Divider,
  Avatar,
  Button,
  CircularProgress
} from '@mui/material';
import { buyPlayer } from '../functions/buyPlayer';
import toast from 'react-hot-toast';
import { useInView } from 'react-intersection-observer';
import { getPriceFromDB } from '../functions/utils';
import { accountInfo } from '../functions/auth';
import { useSettings } from '../contexts/SettingsContext';

const formatExpiryTime = (remainingTime) => {
  if (remainingTime <= 0) {
    return 'Expired';
  }

  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);
  const seconds = remainingTime % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else if (minutes > 0) {
    return `${minutes}m`;
  } else {
    return `${seconds}s`;
  }
};

const SearchResult = ({ card, sessionId, onPriceFetch }) => {
  const [remainingTime, setRemainingTime] = useState(card.expires);
  const [currentTradeState, setCurrentTradeState] = useState(card.tradeState);
  const [isBuying, setIsBuying] = useState(false);
  const [price, setPrice] = useState(null);
  const [loadingPrices, setLoadingPrices] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const { settings } = useSettings();

  const {
    tradeId,
    itemData: { assetId, rating, rareflag, nation, leagueId, teamid, preferredPosition },
    buyNowPrice,
    startingBid,
  } = card;

  // Intersection Observer for Lazy Loading
  const { ref, inView } = useInView({
    threshold: 0.1, // Fetch when 10% of the card is in view
    triggerOnce: true, // Ensure trigger happens only once
  });

  useEffect(() => {
    if (remainingTime > 0) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setCurrentTradeState('Expired');
    }
  }, [remainingTime]);

  useEffect(() => {
    const fetchPrice = async (retryCount = 0) => {
      if (inView && !hasFetched) {
        setLoadingPrices(true);
        try {
          const resp = await getPriceFromDB(
            settings.searchSettings.priceMode,
            assetId,
            rating,
            rareflag,
            nation,
            leagueId,
            teamid
          );
          if (resp === null) {
            console.log('Error fetching price for player Id', assetId, rating, rareflag, nation, leagueId, teamid);
          }
          setPrice(resp);
          setLoadingPrices(false);
          setHasFetched(true);
          if (onPriceFetch) onPriceFetch();
        } catch (error) {
          console.log('Error fetching price:', error);
          if (error.response && error.response.status === 429 && retryCount < 3) {
            console.log('Retrying fetch price due to 429 error, attempt:', retryCount + 1);
            setTimeout(() => fetchPrice(retryCount + 1), 2000); // Retry after 2 seconds
          } else {
            setLoadingPrices(false);
          }
        }
      }
    };

    fetchPrice();
  }, [inView, hasFetched, assetId, rating, rareflag, nation, leagueId, teamid, settings.searchSettings.priceMode, onPriceFetch]);

  const handleBuyNow = async () => {
    setIsBuying(true);
    try {
      const buyResp = await buyPlayer(tradeId, buyNowPrice, sessionId);
      console.log(buyResp);
      if (buyResp['auctionInfo'][0].tradeState === 'closed') {
        toast.success('Player bought successfully');
        toast.success(`You have ${buyResp.credits} coins remaining`);
        setCurrentTradeState('closed');
      } else {
        toast.warning('Trade state is not set to closed. Cannot confirm if player bought or not');
      }
      setIsBuying(false);
    } catch (error) {
      console.log('Error while buying player try again later', error);
      toast.error('Error while buying player try again later');
      setIsBuying(false);
    }
  };

  return (
    <Card
      variant="outlined"
      ref={ref}
      sx={{ marginBottom: 2, bgcolor: '#f8f9fa', border: '1px solid #ddd' }}
    >
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <Avatar
              src={`https://cdn.futbin.com/content/fifa25/img/players/${assetId}.png`}
              alt="Player Image"
              sx={{ width: { xs: 60, sm: 80 }, height: { xs: 60, sm: 80 } }}
            />
          </Grid>
          <Grid item xs={12} sm={9} md={10}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body1"
                  sx={{ color: 'green' }}
                >
                  <span>Rating: </span>{rating}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: 'green' }}
                >
                  <span>Position: </span>{preferredPosition}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="body2"
                  sx={{ color: 'green' }}
                >
                  <span>Buy Now Price: </span>{buyNowPrice}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: 'green' }}
                >
                  <span>Starting Bid:</span> {startingBid}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Chip label={currentTradeState} color={currentTradeState !== 'Expired' ? "success" : "error"} />
                <Typography
                  variant="body2"
                  sx={{ color: '#757575', fontSize: '0.9rem' }}
                >
                  Expires in: {formatExpiryTime(remainingTime)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ marginY: 1 }} />

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <Typography
              variant="body2"
              sx={{ color: '#333', fontWeight: 'bold' }}
            >
              Price Range: <br />
              <span style={{ color: loadingPrices ? '#757575' : price ? '#4caf50' : '#f44336' }}>
                {loadingPrices ? <CircularProgress size={15} /> : price !== null ? `${price[`${accountInfo.platform.toLowerCase()}`]?.min} - ${price[`${accountInfo.platform.toLowerCase()}`]?.max} Coins` : 'N/A'}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography
              variant="body2"
              sx={{ color: '#333', fontWeight: 'bold' }}
            >
              LCP: <br />
              <span style={{ color: loadingPrices ? '#757575' : price ? '#4caf50' : '#f44336' }}>
                {loadingPrices ? <CircularProgress size={15} /> : price !== null ? `${price[`${accountInfo.platform.toLowerCase()}`]?.LCPrice} Coins` : 'N/A'}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography
              variant="body2"
              sx={{ color: '#333', fontWeight: 'bold' }}
            >
              PRP<br />
              <span style={{ color: loadingPrices ? '#757575' : price ? '#4caf50' : '#f44336' }}>
                {loadingPrices ? <CircularProgress size={15} /> : price !== null ? `${price[`${accountInfo.platform.toLowerCase()}`]?.prp}%` : 'N/A'}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              color={currentTradeState === 'closed' ? "success" : 'primary'}
              onClick={handleBuyNow}
              sx={{ width: '100%' }}
              disabled={currentTradeState === 'Expired' || currentTradeState === 'closed' || isBuying}
            >
              {isBuying ? 'Buying' : currentTradeState === "closed" ? `Bought for ${buyNowPrice}` : 'Buy Now'}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SearchResult;
