import React, { useState } from 'react';
import { useSettings } from '../contexts/SettingsContext';
import {
  TextField,
  Typography,
  Select,
  MenuItem,
  Box,
  Grid,
  InputLabel,
  FormControl,
  Switch,
  FormControlLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TransferList from '../components/TransferList';
import Sidebar from '../components/Sidebar';
import toast from 'react-hot-toast';

const SettingsPage = () => {
  const { settings, updateSetting } = useSettings();
  const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
  const [isLoadDialogOpen, setLoadDialogOpen] = useState(false);
  const [settingsName, setSettingsName] = useState('');
  const [savedSettings, setSavedSettings] = useState([]);

  // Handle save settings
  const handleSaveSettings = () => {
    const saved = JSON.parse(localStorage.getItem('savedSettings')) || {};
    saved[settingsName] = settings;
    localStorage.setItem('savedSettings', JSON.stringify(saved));
    setSaveDialogOpen(false);
    toast.success(`Settings saved as "${settingsName}"`)
  };

  // Handle load settings
  const handleLoadSettings = (name) => {
    const saved = JSON.parse(localStorage.getItem('savedSettings')) || {};
    if (saved[name]) {
      Object.keys(saved[name]).forEach((category) => {
        Object.keys(saved[name][category]).forEach((key) => {
          updateSetting(category, key, saved[name][category][key]);
        });
      });
      toast.success(`Settings loaded from "${name}"`)
    }
    setLoadDialogOpen(false);
  };

  // Handle delete settings
  const handleDeleteSettings = (name) => {
    const saved = JSON.parse(localStorage.getItem('savedSettings')) || {};
    delete saved[name];
    localStorage.setItem('savedSettings', JSON.stringify(saved));
    setSavedSettings(Object.keys(saved));
    alert(`Settings "${name}" deleted.`);
  };

  // Fetch saved settings for the load dialog
  const fetchSavedSettings = () => {
    const saved = JSON.parse(localStorage.getItem('savedSettings')) || {};
    setSavedSettings(Object.keys(saved));
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {/* Search Settings */}
        <Typography variant="h5" gutterBottom>
          Search Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              label="Min Delay after each Search(in sec)"
              type="number"
              value={settings.searchSettings.minRefreshTime || 5}
              onChange={(e) => updateSetting('searchSettings', 'minRefreshTime', Number(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Max Delay after each Search(in sec)"
              type="number"
              value={settings.searchSettings.maxRefreshTime || 9}
              onChange={(e) => updateSetting('searchSettings', 'maxRefreshTime', Number(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Pause after no of Searches"
              type="number"
              value={settings.searchSettings.pauseAfter || 100}
              onChange={(e) => updateSetting('searchSettings', 'pauseAfter', Number(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Pause Time(in sec)"
              type="number"
              value={settings.searchSettings.pauseTime || 20}
              onChange={(e) => updateSetting('searchSettings', 'pauseTime', Number(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Stop After Number of Searches"
              type="number"
              value={settings.searchSettings.stopAfterSearches}
              onChange={(e) => updateSetting('searchSettings', 'stopAfterSearches', Number(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl>
              <TextField
                label="No of players per Page"
                type="number"
                value={settings.searchSettings.noOfPlayersPerPage}
                onChange={(e) => updateSetting('searchSettings', 'noOfPlayersPerPage', Number(e.target.value))}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth >
              <InputLabel>Page Mode</InputLabel>
              <Select
                value={settings.searchSettings.pageMode}
                onChange={(e) => updateSetting('searchSettings', 'pageMode', e.target.value)}
              >
                <MenuItem value={1}>1 hour</MenuItem>
                <MenuItem value={2} disabled>2 hour</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={3}>
            <FormControl>
              <TextField
                label="Page Filter range (in sec)"
                type="number"
                value={settings.searchSettings.pageModeAllowance}
                onChange={(e) => updateSetting('searchSettings', 'pageModeAllowance', Number(e.target.value))}
                fullWidth
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Price Mode</InputLabel>
              <Select
                value={settings.searchSettings.priceMode}
                onChange={(e) => updateSetting('searchSettings', 'priceMode', e.target.value)}
              >
                <MenuItem value="futbin">Futbin</MenuItem>
                {/* <MenuItem value="futwiz" disabled>Futwiz (Coming Soon...)</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={settings.searchSettings.autoSearchEnabled}
                  onChange={(e) => updateSetting('searchSettings', 'autoSearchEnabled', e.target.checked)}
                />
              }
              label="Let Autobuyer search the target page Automatically"
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography gutterBottom variant='h6'>Multiple Rarities</Typography>
            <TransferList
              selectedItems={settings.searchSettings.rarities}
              onChange={(selectedItems) => updateSetting('searchSettings', 'rarities', selectedItems)}
            />
          </Grid>

        </Grid>

        {/* Automatic Buy Settings */}
        <Typography variant="h5" gutterBottom mt={4}>
          Automatic Buy Settings
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Profit Type</InputLabel>
              <Select
                value={settings.autoBuySettings.profitType}
                onChange={(e) => updateSetting('autoBuySettings', 'profitType', e.target.value)}
              >
                <MenuItem value="fixed value">Fixed Value</MenuItem>
                <MenuItem value="percentage" disabled>Percentage (Coming Soon...)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Minimum Profit Percentage"
              type="number"
              disabled={settings.autoBuySettings.profitType !== 'percentage'}
              value={settings.autoBuySettings.minProfitPercentage}
              onChange={(e) => updateSetting('autoBuySettings', 'minProfitPercentage', Number(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Minimum Guaranteed Profit"
              type="number"
              disabled={settings.autoBuySettings.profitType !== 'fixed value'}
              value={settings.autoBuySettings.minGuaranteedProfit}
              onChange={(e) => updateSetting('autoBuySettings', 'minGuaranteedProfit', Number(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={settings.autoBuySettings.autoListEnabled}
                  onChange={(e) => updateSetting('autoBuySettings', 'autoListEnabled', e.target.checked)}
                />
              }
              label="Enable Auto List"
            />
            <FormControl fullWidth disabled={!settings.autoBuySettings.autoListEnabled}>
              <InputLabel>Auto List</InputLabel>
              <Select
                value={settings.autoBuySettings.autoList}
                onChange={(e) => updateSetting('autoBuySettings', 'autoList', e.target.value)}
              >
                <MenuItem value="current price">Current Price</MenuItem>
                <MenuItem value="average price">Average Price</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Stop after number of buys"
              type="number"
              value={settings.autoBuySettings.noOfBuys}
              onChange={(e) => updateSetting('autoBuySettings', 'noOfBuys', Number(e.target.value))}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Notification Settings */}
        <Typography variant="h5" gutterBottom mt={4}>
          Notification Settings
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Discord Webhook URL"
              value={settings.notificationSettings.discordWebhookUrl}
              onChange={(e) => updateSetting('notificationSettings', 'discordWebhookUrl', e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Save and Load Buttons */}
        <Box sx={{ mt: 4, display: 'flex', gap: 2, pb: 4 }}>
          <Button variant="contained" color="primary" onClick={() => setSaveDialogOpen(true)}>
            Save Settings
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              fetchSavedSettings();
              setLoadDialogOpen(true);
            }}
          >
            Load Settings
          </Button>
        </Box>

        {/* Save Dialog */}
        <Dialog open={isSaveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
          <DialogTitle>Save Settings</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter a name to save your current settings:</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Settings Name"
              fullWidth
              value={settingsName}
              onChange={(e) => setSettingsName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSaveSettings}>Save</Button>
          </DialogActions>
        </Dialog>

        {/* Load Dialog */}
        <Dialog open={isLoadDialogOpen} onClose={() => setLoadDialogOpen(false)}>
          <DialogTitle>Load Settings</DialogTitle>
          <DialogContent>
            {savedSettings.length ? (
              savedSettings.map((name) => (
                <Box key={name} sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                  <Button onClick={() => handleLoadSettings(name)} sx={{ flexGrow: 1 }}>
                    {name}
                  </Button>
                  <IconButton onClick={() => handleDeleteSettings(name)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))
            ) : (
              <DialogContentText>No saved settings found.</DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setLoadDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default SettingsPage;
