import { createContext, useState, useContext, useCallback, useEffect } from "react";
import toast from 'react-hot-toast';
import { getAuth } from "../functions/auth";

const sessionContext = createContext();

export const useSession = () => useContext(sessionContext);

export const SessionProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [sessionId, setSessionId] = useState(null);

    const updateToken = (newToken) => {
        setToken(newToken);
    }

    const updateSessionId = useCallback((newSessionId) => {
        setSessionId(newSessionId);
    }, []);

    // Function to get a new session ID
    const fetchSessionId = useCallback(
        async (accessToken) => {
            try {
                const sid = await getAuth(accessToken);
                updateSessionId(sid);
                if (sid) {
                    toast.success('New Session created')
                    return sid;
                } else {
                    localStorage.removeItem('access_token')
                    toast.error('Error creating Session, Check access token value.')
                }
            } catch (error) {
                if (error.response?.status === 428) {
                    toast.error('Complete captcha verification in FUT Webapp to proceed')
                }
                console.error('Failed to get new session ID:', error);
                toast.error('Error creating a session. Try refreshing the page')
            }
        },
        [updateSessionId]
    );

    useEffect(() => {
        if (token && !sessionId) {
            fetchSessionId(token);
        }
    }, [token, sessionId, fetchSessionId]);

    return (
        <sessionContext.Provider value={{ token, sessionId, updateToken, updateSessionId, fetchSessionId }}>
            {children}
        </sessionContext.Provider>
    );
}