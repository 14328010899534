import React from 'react';
import ReactDOM from 'react-dom/client';
import { SettingsProvider } from './contexts/SettingsContext';
import { SessionProvider } from './contexts/sessionContext';
import { FiltersProvider } from './contexts/filtersContext';
import { AutobuyerProvider } from './contexts/autobuyerContext';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <SessionProvider>
      <SettingsProvider>
        <FiltersProvider>
          <AutobuyerProvider>
            <App />
          </AutobuyerProvider>
        </FiltersProvider>
      </SettingsProvider>
    </SessionProvider>
  </>
);