import { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Avatar } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import Coins from '../Assets/Coin.webp';
import { formatExpiryTime, getPlayername, getRarityName } from '../functions/utils';
// import MiniPlayerCard from './MiniPlayerCard';
// import { useSession } from '../contexts/sessionContext';
// import { useFilters } from '../contexts/filtersContext';

const SearchLog = ({ searchLogs }) => {
    const [updatedSearchResult, setUpdatedSearchResult] = useState([]);
    const [renderImage, setRenderImage] = useState(true);
    // const [selectedPlayer, setSelectedPlayer] = useState(null); // State to track the selected player
    // const [playerSearchResult, setPlayerSearchResult] = useState([]); // Search result for the clicked player
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for drawer
    // const { sessionId } = useSession();
    // const { filters } = useFilters();

    useEffect(() => {
        const fetchPlayerNames = async () => {
            const logsWithNames = await Promise.all(
                searchLogs.map(async (log) => ({
                    ...log,
                    playerName: await getPlayername(log.itemData.assetId),
                }))
            );
            setUpdatedSearchResult(logsWithNames);
        };
        fetchPlayerNames();
    }, [searchLogs]);

    // const handlePlayerClick = async (player) => {
    //     setSelectedPlayer(player);
    //     setIsDrawerOpen(true);

    //     const searchResult = await searchPlayer(filters,player.itemData.assetId,sessionId);
    //     const validItems = searchResult.filter(
    //         (item) => parseInt(item.expires) >= 3540 &&
    //             parseInt(item.expires) < 7200
    //     );
    //     setPlayerSearchResult(searchResult);
    // };

    // const handleDrawerClose = () => {
    //     setIsDrawerOpen(false);
    //     setSelectedPlayer(null);
    // };

    return (
        <Box display="flex" sx={{ height: '100vh', width: '100%' }}>
            <Paper elevation={3} sx={{ flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#2e2e2e', color: 'white', borderRadius: 1 }}>
                {/* Header */}
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'inherit',
                        p: 1,
                        borderBottom: '1px solid rgba(255, 255, 255, 0.30)',
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 0 }}>
                        Search Log
                    </Typography>
                </Box>

                {/* Table Section */}
                <Box
                    sx={{
                        flex: 1,
                        p: 1,
                        pt: 0,
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#2e2e2e',
                        }
                    }}
                >
                    <TableContainer sx={{ maxHeight: '90vh', width: '100%' }}>
                        <Table size="small" stickyHeader aria-label="auction table" sx={{ backgroundColor: "inherit" }}>
                            <TableHead >
                                <TableRow>
                                    <TableCell align="center" sx={{ backgroundColor: 'black',color: 'white', p: 0 }}>
                                        <IconButton aria-label='image visibility' onClick={() => setRenderImage(!renderImage)} sx={{ color: renderImage ? "green" : "white" }}>
                                            <VisibilityIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: 'black',color: 'white', p: 0 }}>Player Name</TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: 'black',color: 'white', p: 0 }}>Rating</TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: 'black',color: 'white', p: 0 }}>Rare Flag</TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: 'black',color: 'white', p: 0 }}>Starting Bid</TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: 'black',color: 'white', p: 0 }}>Buy Now Price</TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: 'black',color: 'white', p: 0 }}>Expires</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {updatedSearchResult.length === 0 && <TableRow><TableCell colSpan={7} align="center" sx={{ color: 'white' }}>Found 0 matching results</TableCell></TableRow>}
                                {updatedSearchResult.length > 0 && updatedSearchResult.map((player, index) => (
                                    <TableRow hover key={index}>
                                        {renderImage ?
                                            <TableCell align="center" sx={{ p: 0 }}>
                                                <Avatar
                                                    src={`https://cdn.futbin.com/content/fifa25/img/players/${player.itemData.assetId}.png`}
                                                    alt="Player Image"
                                                    sx={{ width: { xs: 40, sm: 40 }, height: { xs: 40, sm: 40 } }}
                                                />
                                            </TableCell>
                                            :
                                            <TableCell align="center" sx={{ color: 'white', p: 0 }}><PersonIcon /></TableCell>
                                        }
                                        <TableCell align="left" sx={{ color: 'white', p: 0 }}>{player.playerName || 'Loading...'} ({player.tradeId})</TableCell>
                                        <TableCell align="center" sx={{ color: 'white', p: 0 }}>{player.itemData.rating}</TableCell>
                                        <TableCell align="center" sx={{ color: 'white', p: 0 }}>{getRarityName(player.itemData.rareflag)}</TableCell>
                                        <TableCell align="center" sx={{ color: 'white', p: 0 }}>{player.startingBid} <img src={Coins} alt="Coins" /></TableCell>
                                        <TableCell align="center" sx={{ color: 'white', p: 0 }}>{player.buyNowPrice} <img src={Coins} alt="Coins" /></TableCell>
                                        <TableCell align="center" sx={{ color: 'white', p: 0 }}>{formatExpiryTime(player.expires)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>

            {/* <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={handleDrawerClose}
                sx={{ '& .MuiDrawer-paper': { width: '30%', backgroundColor: '#2e2e2e', color: 'white' } }}
            >
                <Box p={2}>
                    <Typography variant="h6">Player Details</Typography>
                    <Box mt={2}>
                        <Typography variant="subtitle1">Search Results for {selectedPlayer?.playerName}</Typography>
                        {playerSearchResult.length > 0 ? (
                            playerSearchResult.map((player, index) => (
                                <MiniPlayerCard key={index} playerName={selectedPlayer?.playerName} player={player} />
                            ))
                        ) : (
                            <Typography>No results found</Typography>
                        )}
                    </Box>
                </Box>
            </Drawer> */}
        </Box>
    );
};

export default SearchLog;