import { useState, useContext, createContext } from "react";

const filtersContext = createContext();

export const useFilters = () => {
    return useContext(filtersContext);
}

export const FiltersProvider = ({children}) => {
    const [filters, setFilters] = useState({
        num: 50,         // Total number of results to return as response
        start: 0,        // For pagination
        maskedDefId: 0,  // Player ID
        type: 'player',  // Type - player for player, staff for manager, etc.
        lev: '',         // Quality - bronze, silver, gold, special
        rare: '',        // Quality alternate for special value
        rarityIds: '',   // Rarity IDs - 0, 1, 3 for common, rare, team of the week
        pos: '',         // Position - same as label name
        zone: '',        // Position alternate for Attacker, Defense, Midfielders values
        playStyle: 0,    // Chemistry style - number/id of the style
        nat: 0,          // Nationality - number/Id respective to nationality
        leag: 0,         // League - league Id
        team: 0,         // Club - club/team Id
        icontraits: '',  // Playstyles - equal to 1 if playstyles + is chosen
        micr: 150,         // Minimum bidding price
        macr: 0,         // Maximum bidding price
        minb: 0,         // Minimum buying price
        maxb: 0,         // Maximum buying price
    });

    return (
        <filtersContext.Provider value={{filters, setFilters}}>
            {children}
        </filtersContext.Provider>
    )
}