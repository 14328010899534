import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, CircularProgress } from '@mui/material';
import Sidebar from '../components/Sidebar';
import SearchFilters from '../components/SearchFilters';
import SearchLog from '../components/SearchLog';
import SearchResultsContainer from '../components/SearchResultContainer';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { sleep } from '../const';
import { initializeOptions } from '../functions/optionsLoader';
import { getAuth, accountInfo } from '../functions/auth';
import { baseUrl } from '../const';
import { buyPlayer } from '../functions/buyPlayer';
import { sendPinEvents } from '../functions/pinEvents';
import {
    buildManualQueryString,
    getTime,
    getRandomRefreshTime,
    formatExpiryTime,
    getPriceFromDB,
    getFilterRange,
    getPlayername,
    getRarityName,
    // getTradeStatus
} from '../functions/utils';
import { useSettings } from '../contexts/SettingsContext';
import { useSession } from '../contexts/sessionContext';
import { useFilters } from '../contexts/filtersContext';
import { useAutobuyerData } from '../contexts/autobuyerContext';

const TransferMarket = () => {
    const { token, sessionId, updateSessionId, fetchSessionId } = useSession();
    const [searchResults, setSearchResults] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [previouslyVisitedPage, setPreviouslyVisitedPage] = useState(0);
    const [pageLoading, setPageLoading] = useState(true);
    const [options, setOptions] = useState(null);
    const [rollingCardList, setRollingCardList] = useState([]);
    const { analyticsData, updateAnalyticsData, pauseElapsedTime, startSleepTimer, updateLog, isBotActive, setIsBotActive } = useAutobuyerData();
    const { filters } = useFilters();
    const { settings } = useSettings();

    const abortControllerRef = useRef(null);
    const isBotActiveRef = useRef(isBotActive);

    useEffect(() => {
        isBotActiveRef.current = isBotActive;
    }, [isBotActive]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const initializedOptions = await initializeOptions();
                setOptions(initializedOptions);
                setPageLoading(false);
            } catch (error) {
                console.error("Error initializing options:", error);
                setPageLoading(false);
            }
        };

        fetchOptions();
    }, []);

    useEffect(() => {
        if (!sessionId || !accountInfo.personaId || !accountInfo.pid) return;
        const sendEvents = () => {
            sendPinEvents(sessionId, accountInfo.personaId, accountInfo.pid, 'Transfer Market Search');
        };
        sendEvents();
    }, [sessionId]);

    const handleSearch = async (page = currentPage) => {
        setSearchResults([])
        setSearchLoading(page === 0);
        setIsFetchingNextPage(page > 0);

        const {
            rarities,
            noOfPlayersPerPage
        } = settings.searchSettings;

        let sid = sessionId

        if (!sid || isFetching) return; // Prevent multiple retries at the same time
        try {

            if (previouslyVisitedPage !== 0 && Math.abs(page - previouslyVisitedPage) > 1) {
                console.log("Page jump detected, creating a new session...");
                const newSid = await getAuth(token); // Create a new session ID
                sid = newSid
                updateSessionId(newSid);
            }

            let updatedFilters = {
                ...filters,
                num: noOfPlayersPerPage,
                start: page * noOfPlayersPerPage,
            };
            if (rarities) {
                updatedFilters = { ...updatedFilters, rarityIds: rarities };
            }

            const queryString = buildManualQueryString(updatedFilters, sid);
            console.log(`/transfermarket/search?${queryString}`);
            const response = await axios.get(`${baseUrl}/transfermarket/search?${queryString}`);
            let auctionInfo = response.data.auctionInfo;

            console.log(auctionInfo);
            if (!auctionInfo || auctionInfo.length === 0) {
                console.log('No more results found, stopping search.');
                setSearchLoading(false);
                setIsFetchingNextPage(false);
                sendPinEvents(sessionId, accountInfo.personaId, accountInfo.pid, 'Transfer Market Search');
                return;
            }

            sendPinEvents(sessionId, accountInfo.personaId, accountInfo.pid, "Transfer Market Results - List View");
            setSearchResults(auctionInfo);

            setPreviouslyVisitedPage(page);

        } catch (error) {
            if (error.response?.status === 401 && !isFetching) {
                console.log('Session expired, fetching new session...');
                toast.error('Session expired, creating new session...')
                setIsFetching(true);
                await fetchSessionId(token);
                setIsFetching(false);
            }
            if (error.response?.status === 458) {
                toast.error('Complete captcha verification in FUT Webapp to proceed')
            }
        } finally {
            setSearchLoading(false);
            setIsFetchingNextPage(false);
        }
    };

    const handleNextPage = () => {
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        setSearchResults([])
        handleSearch(nextPage);
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
            setSearchResults([])
            handleSearch(prevPage);
        }
    };

    const setLog = (type, message) => {
        updateLog({
            type,
            message,
            time: getTime(),
        })
    };

    useEffect(() => {
        abortControllerRef.current = new AbortController();
        isBotActiveRef.current = isBotActive;
        setRollingCardList([]);
        
        let targetPage = currentPage;
        let sid = sessionId;
        let searches = 0;
        let retryCount = 0;
        
        const updateRollingCardList = async (sid) => {
            if (!isBotActiveRef.current) {
                pauseElapsedTime();
                return;
            }
            
            let updatedFilters = {
                ...filters,
                num: settings.searchSettings.noOfPlayersPerPage,
                start: targetPage * settings.searchSettings.noOfPlayersPerPage,
            };
    
            if (settings.searchSettings.rarities) {
                updatedFilters = { ...updatedFilters, rarityIds: settings.searchSettings.rarities };
            }
    
            const filterRange = await getFilterRange(settings.searchSettings.pageMode, settings.searchSettings.pageModeAllowance);
    
            const fetchPageData = async (pageNum, sid) => {
                let pageFilters = { ...updatedFilters, start: pageNum * settings.searchSettings.noOfPlayersPerPage };
                const queryString = buildManualQueryString(pageFilters, sid);
                console.log(pageNum,queryString);
                const response = await axios.get(`${baseUrl}/transfermarket/search?${queryString}`, { signal: abortControllerRef.current.signal });
                console.log(response.data.auctionInfo);
                return response.data.auctionInfo || [];
            };

            try {
                let combinedItems = [];
                let validResults = [];
    
                while (validResults.length < settings.searchSettings.noOfPlayersPerPage) {
                    let currentItems = await fetchPageData(targetPage, sid);
                    combinedItems.push(...currentItems);
                    
                    if (!currentItems.length) {
                        targetPage = Math.max(0, targetPage - 1);
                        setCurrentPage(targetPage);
                        await sleep(getRandomRefreshTime(500,2000));
                        continue;
                    }

                    let firstExpiry = currentItems[0]?.expires;
                    let lastExpiry = currentItems[currentItems.length - 1]?.expires;
    
                    validResults = combinedItems.filter(item => 
                        parseInt(item.expires) >= parseInt(filterRange['min']) &&
                        parseInt(item.expires) <= parseInt(filterRange['max'])
                    );
    
                    if (validResults.length < settings.searchSettings.noOfPlayersPerPage && lastExpiry < parseInt(filterRange['max'])) {
                        targetPage += 1;
                        setCurrentPage(targetPage);
                        await sleep(getRandomRefreshTime(500,2000));
                    } else if (firstExpiry > parseInt(filterRange['min'])) {
                        targetPage = Math.max(0, targetPage - 1);
                        setCurrentPage(targetPage);
                        await sleep(getRandomRefreshTime(500,2000));
                    } else {
                        break;
                    }
                }
    
                setRollingCardList(validResults);
                searches++;
                updateAnalyticsData('searches', searches);
    
                if (searches >= settings.searchSettings.stopAfterSearches) {
                    setLog('info', 'Sniping bot stopped‼️‼️ Search limit reached');
                    pauseElapsedTime();
                    toast.error('Sniping Bot Stopped‼️‼️ Search limit reached');
                    return;
                }
    
                if (searches !== 0 && searches % settings.searchSettings.pauseAfter === 0) {
                    setLog('info', `Pausing for ${settings.searchSettings.pauseTime} seconds after ${settings.searchSettings.pauseAfter} searches`);
                    startSleepTimer(settings.searchSettings.pauseTime);
                    await sleep(settings.searchSettings.pauseTime * 1000);
                } else {
                    const refreshTime = getRandomRefreshTime(settings.searchSettings.maxRefreshTime, settings.searchSettings.minRefreshTime);
                    startSleepTimer(refreshTime);
                    await sleep(refreshTime * 1000);
                }
    
                if (isBotActiveRef.current) {
                    await updateRollingCardList(sid);
                }
            } catch (error) {
                if (abortControllerRef.current.signal.aborted) {
                    setLog('info', 'Sniping bot Stopped‼️‼️');
                    return;
                }
    
                if (error.response?.status === 458) {
                    toast.error('Complete captcha verification in FUT Webapp to proceed');
                    setLog('failure', 'Complete captcha verification in FUT Webapp to proceed');
                    return;
                }
    
                if (error.response?.status === 401) {
                    sid = await fetchSessionId(token);
                    updateSessionId(sid);
                }
    
                retryCount++;
                if (retryCount > 5) {
                    pauseElapsedTime();
                    return;
                }
    
                await sleep(5 * 1000);
    
                if (isBotActiveRef.current) {
                    await updateRollingCardList(sid);
                }
            }
        };
    
        if (isBotActiveRef.current) {
            updateRollingCardList(sid);
        }
    
        return () => {
            abortControllerRef.current.abort();
        };

        // eslint-disable-next-line
    }, [isBotActive]);

    useEffect(() => {
        const snipeFromRollingCardList = async () => {
            if (!isBotActive) return;
            let purchases = 0;
            const noOfBuys = settings.autoBuySettings.noOfBuys;

            for (const item of rollingCardList) {
                if (purchases >= noOfBuys) {
                    setLog('info', 'Sniping bot Stopped‼️‼️ Purchase limit reached')
                    pauseElapsedTime();
                    toast.error('Sniping Bot Stopped‼️‼️ Purchase limit reached')
                    return;
                }
                const price = await getPriceFromDB(settings.searchSettings.priceMode, item.itemData.assetId, item.itemData.rating, item.itemData.rareflag, item.itemData.nation, item.itemData.leagueId, item.itemData.teamid);
                const playerName = await getPlayername(item.itemData.assetId);
                const playerRarity = getRarityName(item.itemData.rareflag);

                let profit;
                if (price && parseInt(price[`${accountInfo?.platform?.toLowerCase()}`]?.LCPrice.replace(',', '')) === 0) {
                    profit = parseInt(price[`${accountInfo?.platform?.toLowerCase()}`]?.max.replace(',', '')) - (item.buyNowPrice + (item.buyNowPrice * 0.05));
                } else if (price) {
                    profit = parseInt(price[`${accountInfo?.platform?.toLowerCase()}`]?.LCPrice.replace(',', '')) - (item.buyNowPrice + (item.buyNowPrice * 0.05));
                }
                else {
                    continue
                }

                if (profit > 0 && profit < settings.autoBuySettings.minGuaranteedProfit) {
                    setLog('info', `Found item - ${playerName} from ${playerRarity} \n BuyNowPrice: ${item.buyNowPrice}, Expires: ${formatExpiryTime(item.expires)} Futbin Currentprice: ${price ? price[`${accountInfo.platform.toLowerCase()}`]?.LCPrice : 'N/A'} 
                    profit: ${profit}`);
                }
                if (accountInfo.credits < item.buyNowPrice && profit > 0) {
                    setLog('failure', `Insufficient coins to buy player. Missed player with profit ${profit}`);
                    toast.error('Insufficient coins to buy player')
                    continue;
                }

                if (settings.autoBuySettings.profitType === 'fixed value' && profit >= settings.autoBuySettings.minGuaranteedProfit) {
                    const buyResp = await buyPlayer(item.tradeId, item.buyNowPrice, sessionId);
                    if (buyResp?.auctionInfo[0]?.tradeState === 'closed') {
                        setLog('success', `${playerName} bought for ${item.buyNowPrice}, Profit: ${profit}`);
                        toast.success('Player bought successfully')
                        accountInfo.credits = parseInt(buyResp.credits);
                        purchases++;
                        updateAnalyticsData('purchases', analyticsData.purchases + 1);
                        updateAnalyticsData('profit', analyticsData.profit + profit);
                    } else {
                        console.log('purchase failed', buyResp);
                        setLog('failure', 'Failed to buy player');
                        toast.error('Player Missed')
                        updateAnalyticsData('failedPurchases', analyticsData.failedPurchases + 1);
                    }
                }
            }
        };

        snipeFromRollingCardList();
        // eslint-disable-next-line
    }, [rollingCardList]);

    return (
        <Box sx={{ flexGrow: 1, height: '100vh', width: '100vw', backgroundColor: 'white' }}>
            <Grid container spacing={0} height="100%">
                <Grid item xs={0.75}>
                    <Sidebar />
                </Grid>
                <Grid item xs={11.25}>
                    {pageLoading || isFetching ?
                        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
                            <CircularProgress />
                        </Box>
                        : (
                            <Grid container>
                                <Grid item xs={4.5} sx={{ borderRight: '1px solid #e0e0e0' }}>
                                    <SearchFilters
                                        options={options}
                                        handleSearch={handleSearch}
                                        searchLoading={searchLoading}
                                        isFetchingNextPage={isFetchingNextPage}
                                        setIsBotActive={setIsBotActive}
                                        isBotActive={isBotActive}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        setSearchResults={setSearchResults}
                                        accountInfo={accountInfo}
                                    />
                                </Grid>
                                <Grid item xs={7.5}>
                                    {isBotActive ?
                                        <SearchLog
                                            searchLogs={rollingCardList}
                                        />
                                        : <SearchResultsContainer
                                            cards={searchResults}
                                            searchLoading={searchLoading}
                                            sessionId={sessionId}
                                            isFetchingNextPage={isFetchingNextPage}
                                            onNextPage={handleNextPage}
                                            onPreviousPage={handlePreviousPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />}
                                </Grid>
                            </Grid>
                        )}
                </Grid>
            </Grid>
            <Toaster
                position="top-right"
                reverseOrder={true}
                toastOptions={{
                    success: {
                        duration: 4000
                    }
                }}
            />
        </Box>
    );
};

export default TransferMarket;