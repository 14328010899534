import axios from 'axios';
import { baseUrl } from '../const';

export const sendPinEvents = async (sid, pid, pidm,pgid) => {
    try {
        const response = await axios.post(`${baseUrl}/pinevents`, {
            custom: {
                networkAccess: 'W',
                service_plat: 'pc',
            },
            et: 'client',
            events: [
                {
                    type: 'menu',
                    pgid: pgid,
                    core: {
                        s: 4,
                        pidt: 'persona',
                        pid,
                        ts_event: new Date().toISOString(),
                        en: 'page_view',
                        pidm: {
                            nucleus: pidm,
                        },
                    },
                },
            ],
            gid: 0,
            is_sess: true,
            loc: 'en-US',
            plat: 'web',
            rel: 'prod',
            sid,
            taxv: '1.1',
            tid: 'FUT24WEB',
            tidt: 'easku',
            ts_post: new Date().toISOString(),
            v: '24.7.0',
        }, {
            headers: {
                'Content-Type': 'application/json'
            },
        });
        if(response.status === 200) {
            console.log('Pin events sent successfully');
        }else {
            console.error('Error sending pin events:', response);
        }
        return response.data;
    } catch (error) {
        console.error('Error sending pin events:', error);
        throw error;
    }
};
