import React from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TransferMarket from './pages/TransferMarket';
import Notification from './pages/Notification';
import Settings from './pages/Settings';
import Login from './pages/login';
import PrivateRoute from './components/privateRoute';
import { AuthProvider } from './contexts/loginAuthContext';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login/>} />
          <Route path="/" element={<PrivateRoute><TransferMarket /></PrivateRoute>} />
          <Route path="/notifications" element={<PrivateRoute><Notification /></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
