import React, { useState, useEffect } from 'react';
import { Box, TextField, Grid, MenuItem, Button, InputAdornment, IconButton, Typography, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Autocomplete from '@mui/material/Autocomplete';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { getTime } from '../functions/utils';
import { useAuth } from '../contexts/loginAuthContext';
import { useFilters } from '../contexts/filtersContext';
import Coins from '../Assets/Coin.webp';
import { useAutobuyerData } from '../contexts/autobuyerContext';
import Autobuyer from './Autobuyer';

const SearchFilters = ({ options, handleSearch, searchLoading, isFetchingNextPage, setIsBotActive, isBotActive, currentPage, setCurrentPage, setSearchResults, accountInfo }) => {
    const [clubDisabled, setClubDisabled] = useState(true);
    const [filteredRarityOptions, setFilteredRarityOptions] = useState(options.rarityOptions);
    const { user } = useAuth();
    const { filters, setFilters } = useFilters();
    const { updateLog,pauseElapsedTime, startElapsedTime } = useAutobuyerData();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'pos' && (value === 'defence' || value === 'attacker' || value === 'midfield')) {
            setFilters({
                ...filters,
                'zone': value,
                [name]: value
            })
        } else if (name === 'lev' && value === 'SP') {
            setFilters({
                ...filters,
                'rare': value,
                [name]: value
            })
        } else if ((name === 'maxb' || name === 'minb' || name === 'micr' || name === 'macr') && value==='' ){
            setFilters({
                ...filters,
                [name]: 0,
            });
        }else {
            setFilters({
                ...filters,
                [name]: value,
            });
        }
    };

    const incrementValue = (name) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: parseInt(prevFilters[name]) + 100 > accountInfo.credits ? accountInfo.credits : parseInt(prevFilters[name]) + 100,
        }));
    };

    const decrementValue = (name) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: parseInt(prevFilters[name]) > 0 ? parseInt(prevFilters[name]) - 100 : 0,
        }));
    };

    const filterOptions = (options, { inputValue }) => {
        return options
            .filter(option => option.f.toLowerCase().includes(inputValue.toLowerCase()) || option.l.toLowerCase().includes(inputValue.toLowerCase()))
            .slice(0, 15);
    };

    const handleReset = () => {
        setFilters({
            num: 50,
            start: 0,
            maskedDefId: 0,
            type: 'player',
            lev: '',
            rare: '',
            rarityIds: '',
            pos: '',
            zone: '',
            playStyle: 0,
            nat: 0,
            leag: 0,
            team: 0,
            icontraits: '',
            micr: 0,
            macr: 0,
            minb: 0,
            maxb: 0
        })
        setClubDisabled(true);
        setSearchResults([]);
    }

    useEffect(() => {
        if (filters.maskedDefId) {
            setClubDisabled(true);
            setFilters((prevFilters) => ({
                ...prevFilters,
                nat: 0,
                leag: 0,
                team: 0,
            }));
        } else {
            setClubDisabled(filters.leag === 0);
        }
        // eslint-disable-next-line
    }, [filters.maskedDefId, filters.leag]);

    useEffect(() => {
        if (!filters.lev || filters.lev === '') {
            setFilteredRarityOptions(options.rarityOptions);
            return;
        }

        if (filters.lev === 'SP') {
            setFilteredRarityOptions(
                options.rarityOptions.filter((option) => parseInt(option.id) !== 0 && parseInt(option.id) !== 1)
            );
        } else {
            setFilteredRarityOptions(
                options.rarityOptions.filter((option) => [0, 1, 3].includes(parseInt(option.id)))
            );
        }
        // eslint-disable-next-line
    }, [filters.lev]);

    const startSnipingBot = () => {
        updateLog({
            type: 'info',
            message: "Sniping Bot Started, let's go! 🚀",
            time: getTime(),
        });
        toast.success('Sniping Bot Started')
        setIsBotActive(true);
        startElapsedTime()
        console.log('Sniping bot started');
    };

    const stopSnipingBot = () => {
        toast.error('Sniping Bot Stopped!')
        pauseElapsedTime();
        updateLog({
            type: 'info',
            message: "Sniping Bot Stopped! 🛑",
            time: getTime(),
        });
        setIsBotActive(false);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            < Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 2,
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #ccc',
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }
                }
            >
                <Typography variant="h6">Transfer Market</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="textSecondary" sx={{ marginRight: '5px' }} >Coins: {accountInfo.credits}</Typography>
                    <img src={Coins} alt="Coins" />
                </Box>
            </Box >
            {isBotActive ?
                <Autobuyer />
                :
                <Box sx={{
                    flexGrow: 1, p: 1, pb: 0, overflowY: 'auto', '&::-webkit-scrollbar': {
                        width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#2e2e2e',
                    }
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="textSecondary">
                                Hi, {user.name}! 👋🏻
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                disablePortal
                                autoComplete
                                name='maskedDefId'
                                options={options.playerOptions}
                                getOptionLabel={(option) => `${option.f} ${option.l}`}
                                filterOptions={filterOptions}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.f} {option.l}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Player Name"
                                    />
                                )}
                                onChange={(event, value) => {
                                    if (value) {
                                        console.log("Selected Player ID:", value.id);
                                        setFilters(prevFilters => ({
                                            ...prevFilters,
                                            maskedDefId: value.id,
                                        }));
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label="Quality"
                                variant="outlined"
                                name="lev"
                                value={filters.lev}
                                onChange={handleInputChange}
                            >
                                {Object.keys(options.qualityOptions).map(key => (
                                    <MenuItem key={key} value={options.qualityOptions[key]}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label="Rarity"
                                variant="outlined"
                                name="rarityIds"
                                value={filters.rarityIds}
                                onChange={handleInputChange}
                            >
                                {filteredRarityOptions.map(rareItem => (
                                    <MenuItem key={rareItem['id']} value={rareItem['id']}>
                                        {rareItem['name']}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label="Position"
                                variant="outlined"
                                name="pos"
                                value={filters.pos}
                                onChange={handleInputChange}
                            >
                                {Object.keys(options.zoneOptions).map(key => (
                                    <MenuItem key={key} value={options.zoneOptions[key]}>
                                        {key}
                                    </MenuItem>
                                ))}
                                {options.positionsOptions.map(key => (
                                    <MenuItem key={key} value={key}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label="Chemistry Style"
                                variant="outlined"
                                name="playStyle"
                                value={filters.playStyle === 0 ? '' : filters.playStyle}
                                onChange={handleInputChange}
                            >
                                {Object.keys(options.filterOptionsData['playstyle']).map(key => (
                                    <MenuItem key={key} value={options.filterOptionsData['playstyle'][key]}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label="Nationality"
                                variant="outlined"
                                name="nat"
                                value={filters.nat === 0 ? '' : filters.nat}
                                onChange={handleInputChange}
                                disabled={filters.maskedDefId > 0}
                            >
                                {Object.keys(options.filterOptionsData['nation']).map(key => (
                                    <MenuItem key={key} value={options.filterOptionsData['nation'][key]}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label="League"
                                variant="outlined"
                                name="leag"
                                value={filters.leag === 0 ? '' : filters.leag}
                                onChange={handleInputChange}
                                disabled={filters.maskedDefId > 0}
                            >
                                {options.leagueOptions.map(leagueItem => (
                                    <MenuItem key={leagueItem['id']} value={leagueItem['id']}>
                                        {leagueItem['name']}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label="Club"
                                variant="outlined"
                                name="team"
                                value={filters.team === 0 ? '' : filters.team}
                                onChange={handleInputChange}
                                disabled={clubDisabled || filters.maskedDefId > 0}
                            >
                                {options.clubOptions.map(club => (
                                    <MenuItem key={club['id']} value={club['id']}>
                                        {club['name']}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label="PlayStyles"
                                variant="outlined"
                                name="icontraits"
                                value={filters.icontraits}
                                onChange={handleInputChange}
                            >
                                <MenuItem value={1}>PlayStyles+</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Bid Price: Min"
                                variant="outlined"
                                name="micr"
                                value={filters.micr}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton disabled={filters['micr'] === 0 ? true : false} onClick={() => decrementValue('micr')} >
                                                <RemoveIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => incrementValue('micr')} >
                                                <AddIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Bid Price: Max"
                                variant="outlined"
                                name="macr"
                                value={filters.macr}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton disabled={filters['macr'] === 0 ? true : false} onClick={() => decrementValue('macr')} >
                                                <RemoveIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => incrementValue('macr')} >
                                                <AddIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {/* Buy Now Price */}
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Buy Now Price: Min"
                                variant="outlined"
                                name="minb"
                                value={filters.minb}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton disabled={filters['minb'] === 0 ? true : false} onClick={() => decrementValue('minb')} >
                                                <RemoveIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => incrementValue('minb')} >
                                                <AddIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Buy Now Price: Max"
                                variant="outlined"
                                name="maxb"
                                value={filters.maxb}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton disabled={filters['maxb'] === 0 ? true : false} onClick={() => decrementValue('maxb')}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => incrementValue('maxb')} >
                                                <AddIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="outlined" color="primary" onClick={handleReset}>
                                Reset
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="contained" color="success" onClick={() => { handleSearch() }} disabled={(searchLoading || isFetchingNextPage)} >
                                {(searchLoading || isFetchingNextPage) ? 'Searching...' : 'Search'}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: '0px' }}>
                            <Typography>
                                To Search Multiple Rarities go to <Link to={'/settings'}>Settings</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 1,
                    backgroundColor: '#fff',
                    borderTop: '1px solid #ccc',
                    p: 1,
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    variant="contained"
                    color="error"
                    onClick={stopSnipingBot}
                    disabled={!isBotActive}
                >
                    Stop
                </Button>
                <TextField
                    type='number'
                    label="current Page"
                    variant='outlined'
                    size='small'
                    sx={{
                        width: '100px'
                    }}
                    name='currentPage'
                    value={currentPage + 1}
                    onChange={(e) => {
                        setCurrentPage(isNaN(parseInt(e.target.value)) ? 0 : Math.max(0, parseInt(e.target.value) - 1))
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={startSnipingBot}
                    disabled={isBotActive}
                >
                    Start
                </Button>
            </Stack>
        </Box>
    );
};

export default SearchFilters;   