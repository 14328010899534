import axios from 'axios';
import { baseUrl } from '../const'
import toast from 'react-hot-toast';

export const fetchPlayerDetails = async () => {
    try {
        const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseUrl}/filters/players`,
            headers: {
                'accept': '*/*',
                'content-type': 'application/json'
            }
        };
        const response = await axios.request(config);
        let players
        if(response.data.LegendsPlayers.length > 0 && response.data.Players.length > 0){ 
            players = [...response.data.LegendsPlayers, ...response.data.Players]
        }
        players = [...response.data.Players]
        toast.success('All players updated successfully')
        return players;
    } catch (error) {
        console.error('Error fetching player details:', error);
        toast.error('error fetching players. Try relaunching the website')
        return [];
    }
};