import { Container, Typography, Box, CircularProgress, Button, Stack } from '@mui/material';
import SearchResult from './SearchResult';
import { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

const SearchResultsContainer = ({
    cards,
    searchLoading,
    sessionId,
    isFetchingNextPage,
    onNextPage,
    onPreviousPage,
    currentPage,
}) => {
    //eslint-disable-next-line
    const [cardsInView, setCardsInView] = useState([]);
    const { ref: containerRef, inView } = useInView({
        threshold: 0.1,
    });

    const handlePriceFetch = useCallback(() => {
        setCardsInView((prevCards) => {
            // Fetch prices for the cards in view + next 2 cards
            const nextTwoCards = cards.slice(prevCards.length, prevCards.length + 2);
            return [...prevCards, ...nextTwoCards];
        });
    }, [cards]);

    useEffect(() => {
        if (inView) {
            handlePriceFetch();
        }
    }, [inView, handlePriceFetch]);

    return (
        <Container
            ref={containerRef}
            sx={{
                height: '95vh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                p: 1,
                width: '100%',
                position: 'relative',
            }}
        >
            <Typography
                variant="h5"
                gutterBottom
                sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                    p: 1,
                    borderBottom: '1px solid #ccc',
                }}
            >
                Search Results
            </Typography>

            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: 1,
                }}
            >
                {searchLoading && cards.length === 0 ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '80%',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : cards.length === 0 && !isFetchingNextPage ? (
                    <Typography>There are no results to show :(</Typography>
                ) : (
                    <>
                        {cards.map((card) => (
                            <SearchResult
                                key={card.tradeId}
                                card={card}
                                sessionId={sessionId}
                                onPriceFetch={handlePriceFetch}
                            />
                        ))}
                        {(isFetchingNextPage) && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '10%',
                                    marginTop: 2,
                                }}
                            >
                                <CircularProgress size={20} />
                                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                                    {'Loading more results...'}
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>

            <Stack
                direction="row"
                spacing={2}
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 1,
                    backgroundColor: '#fff',
                    borderTop: '1px solid #ccc',
                    p: 1,
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    variant="outlined"
                    onClick={onPreviousPage}
                    disabled={searchLoading || isFetchingNextPage || currentPage === 0}
                >
                    Previous
                </Button>
                <Button
                    variant="outlined"
                    onClick={onNextPage}
                    disabled={searchLoading || isFetchingNextPage || cards.length === 0}
                >
                    Next
                </Button>
            </Stack>
        </Container>
    );
};

export default SearchResultsContainer;
