import { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const loginAuthContext = createContext();

export const useAuth = () => useContext(loginAuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    const login = (userData) => {
        setUser(userData);
        setIsAuthenticated(true);
        navigate('/');
        toast.success('Successfully logged in')
    };

    const logout = () => {
        setUser(null);
        setIsAuthenticated(false);
        navigate('/login');
        toast.success('Successfully logged out')
    };

    return (
        <loginAuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
            {children}
        </loginAuthContext.Provider>
    );
};