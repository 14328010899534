import React from 'react'
import Sidebar from '../components/Sidebar';
import { Box } from '@mui/material';

function Notification() {
  return (
    <Box sx={{ display: 'flex',backgroundColor:'white',width:'90vw', height:'90vh' }}>
        <Sidebar />
        Notifications
    </Box>
  )
}

export default Notification