import React from 'react';
import { Paper, Typography, Box, Chip } from '@mui/material';

// Define log types and their respective colors
const logStyles = {
    info: { background: '#e0e7ff', border: '#6366f1', color: '#1e3a8a' }, // Blue shades
    success: { background: '#d1fae5', border: '#10b981', color: '#065f46' }, // Green shades
    failure: { background: '#fee2e2', border: '#ef4444', color: '#991b1b' }, // Red shades
    warn: { background: '#fef3c7', border: '#f59e0b', color: '#92400e' }, // Yellow shades
};

const LogTerminal = ({ logs }) => {

    return (
        <Paper elevation={3} sx={{ height: '55vh', display: 'flex', flexDirection: 'column' }}>
            <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: 'inherit',
                    p: 1,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <Typography variant="h6" sx={{ mb: 0 }}>
                    Logs
                </Typography>
            </Box>

            <Box
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#2e2e2e',
                    },
                    p: 1,
                }}
            >
                {logs.map((log, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 1,
                            p: 1,
                            borderRadius: 1,
                            backgroundColor: logStyles[log.type]?.background || '#f0f0f0',
                            border: `1px solid ${logStyles[log.type]?.border || '#ccc'}`,
                        }}
                    >
                        <Chip
                            label={log.type.charAt(0).toUpperCase() + log.type.slice(1)}
                            sx={{
                                backgroundColor: logStyles[log.type]?.border || '#ccc',
                                color: 'white',
                                fontWeight: 'bold',
                                mr: 1,
                            }}
                            size="small"
                        />

                        <Typography
                            variant="body2"
                            sx={{ flex: 1, whiteSpace: 'pre-wrap', color: logStyles[log.type]?.color || '#000' }}
                        >
                            {log.message}
                        </Typography>

                        <Typography
                            variant="caption"
                            sx={{
                                color: logStyles[log.type]?.color || '#000',
                                opacity: 0.7,
                            }}
                        >
                            {log.time}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Paper>
    );
};

export default LogTerminal;
